<template>
  <div class="d-flex align-items-center flex-column">
    <BImg src="/logo.png" width="100%" class="image" />
    <h1 class="title">
      {{ $t('Global.Name') }}
    </h1>
    <h2 class="sub-title">
      {{ $t('Auth.Login.Welcome', { name }) }}
    </h2>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },

  computed: {
    name() {
      const user = JSON.parse(localStorage.getItem('user'))
      if (!user) return ''
      return `${user.firstname} ${user.lastname}`
    },
  },
}
</script>

<style lang="scss" scoped>
.image {
  width: 20%;
  max-height: 250px;
  object-fit: contain;
  margin-bottom: 80px;
}
.title {
  color: var(--primary);
  font-weight: bold;
  opacity: 0.5;
  font-size: 40px;
  margin-bottom: 80px;
}
.sub-title {
  font-size: 30px;
  opacity: 0.49;
  color: #000000;
  font-weight: 600;
}
</style>
